<!-- 计算器 -->
<template>
  <div class="Calculator">
    <div class="main">
      <div class="title">车辆销售价</div>
      <van-field
        v-model="aggregateAmount"
        center
        type="digit"
        :formatter="value => value.replace(/\D|^0/g, '')"
        placeholder="请输入"
      >
        <template #button>
          <span>元</span>
        </template>
      </van-field>
      <div class="title">首付</div>
      <van-row type="flex" justify="space-between" align="center">
        <van-col span="11">
          <van-field
            v-model="initialPercent"
            center
            type="digit"
            placeholder="请输入"
            :formatter="formatterPer"
            @click="showToast(1)"
            :readonly="!aggregateAmount"
          >
            <template #button>
              <span>%</span>
            </template>
          </van-field>
        </van-col>
        <van-col span="2">
          <img
            class="double_arrow"
            src="@/assets/images/my_and_service/double_arrow.png"
          />
        </van-col>
        <van-col span="11">
          <van-field
            v-model="initialPayment"
            center
            type="digit"
            placeholder="请输入"
            @click="showToast(2)"
            :formatter="formatter"
            :readonly="!aggregateAmount"
          >
            <template #button>
              <span>元</span>
            </template>
          </van-field>
        </van-col>
      </van-row>
      <div class="title">融资期数</div>
      <div class="nperWrap">
        <div
          :class="['item', { active: period == item }]"
          v-for="item in nperList"
          :key="item"
          @click="nperChange(item)"
        >
          {{ item }}期
        </div>
      </div>
      <div class="title">年化利率</div>
      <van-field
        v-model="balanceInsteresting"
        center
        type="number"
        placeholder="请输入"
      >
        <template #button>
          <span>%</span>
        </template>
      </van-field>
      <div class="title">附加费金额 <span>（选填）</span></div>
      <van-field v-model="surcharge" center type="number" placeholder="请输入">
        <template #button>
          <span>元</span>
        </template>
      </van-field>
      <div class="title">贴息金额 <span>（选填）</span></div>
      <van-field
        v-model="allowanceInteresting"
        center
        type="number"
        placeholder="请输入"
      >
        <template #button>
          <span>元</span>
        </template>
      </van-field>
      <div class="tip">计算方式为等额本息</div>
      <div class="tip">此结果仅供参考，实际费用以全国提报审核通过后为准</div>
    </div>
    <div class="bottomFixed">
      <div class="clearOut" @click="clearAll">清空</div>
      <div :class="['calculate', { active: canSubmit }]" @click="getResult">
        计算
      </div>
    </div>
  </div>
</template>

<script>
import { calEngine } from "@/api/api_personal";

export default {
  name: "Calculator",
  data() {
    return {
      flagOne: false,
      flagTwo: false,
      initialPayment: null, // 首付款
      initialPercent: null, // 首付比例
      aggregateAmount: null, // 车辆销售价
      balanceInsteresting: null, // 年化利率
      period: null, // 融资期数
      surcharge: null, // 附加费(无值传0)
      allowanceInteresting: null, // 贴息金额(无值传0)
      nperList: [12, 18, 24, 30, 36, 42, 48, 60]
    };
  },
  computed: {
    canSubmit() {
      return (
        this.aggregateAmount &&
        this.initialPayment &&
        this.period &&
        this.balanceInsteresting
      );
    }
  },
  watch: {
    // 监听比例计算首付
    initialPercent() {
      if (this.flagOne) {
        this.initialPayment =
          (this.aggregateAmount * this.initialPercent) / 100 || null;
      }
    },
    // 监听首付计算比例
    initialPayment() {
      if (this.flagTwo) {
        this.initialPercent =
          (this.initialPayment / this.aggregateAmount) * 100 || null;
      }
    }
  },
  methods: {
    // 首付非0正整数，且小于等于100
    formatterPer(value) {
      if (value) {
        const newVal = value.replace(/\D|^0/g, "");
        return Number(newVal) > 100
          ? newVal.substring(0, newVal.length - 1)
          : newVal;
      }
    },
    // 首付非0正整数，且不能大于车辆销售价
    formatter(value) {
      if (value) {
        const newVal = value.replace(/\D|^0/g, "");
        return Number(newVal) > Number(this.aggregateAmount)
          ? newVal.substring(0, newVal.length - 1)
          : newVal;
      }
    },
    nperChange(item) {
      this.period = item;
    },
    showToast(tag) {
      if (!this.aggregateAmount) {
        this.$toast("请先输入车辆销售价");
        return;
      }
      if (tag === 1) {
        // 开始监听比例
        this.flagOne = true;
        this.flagTwo = false;
      } else {
        // 开始监听首付
        this.flagTwo = true;
        this.flagOne = false;
      }
    },
    getResult() {
      calEngine({
        balanceInsteresting: [this.balanceInsteresting], // 年化利率
        period: [this.period], // 融资期数
        aggregateAmount: this.aggregateAmount, // 车辆销售价
        initialPayment: [this.initialPayment], // 首付款
        surcharge: this.surcharge || 0, // 附加费(无值传0)
        allowanceInteresting: this.allowanceInteresting || 0, // 贴息金额(无值传0)
        algorithmFlag: "Alix_PMT",
        compareType: 0, // 比较类型0:计算月供  1:比首付 2:比期数 3:比利率
        fv: 0, // 尾付金额(无值传0)
        adjustRate: 0 // 调整利率(无值传0)
      }).then(res => {
        if (res.code === 0) {
          const result = res.data.info[0];
          this.$router.push({ name: "CalculatorResult", query: result });
        }
      });
    },
    clearAll() {
      this.initialPercent = null; // 首付比例
      this.aggregateAmount = null; // 车辆销售价
      this.balanceInsteresting = null; // 年化利率
      this.period = null; // 融资期数
      this.initialPayment = null; // 首付款
      this.surcharge = null; // 附加费(无值传0)
      this.allowanceInteresting = null; // 贴息金额(无值传0)
    }
  }
};
</script>
<style lang="scss">
.Calculator {
  background: #fff;
  .main {
    padding: 0 0.18rem 0.92rem;
    .title {
      margin: 0.3rem 0 0.16rem;
      font-size: 0.15rem;
      font-weight: bold;
      span {
        color: #8d8d91;
        font-weight: normal;
      }
    }
    .van-cell {
      padding: 0 0.15rem;
      height: 0.44rem;
      border: 0.01rem solid #f1f2f6;
      border-radius: 0.04rem;
      &::after {
        content: none;
      }
      .van-field__control {
        &::placeholder {
          font-size: 0.14rem;
          color: #b9bbbe;
        }
      }
      .van-field__button span {
        font-size: 0.14rem;
        color: #8d8d91;
      }
    }
    .nperWrap {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      height: 0.86rem;
      align-content: space-between;
      .item {
        background: #f5f6f7;
        border-radius: 0.04rem;
        width: 0.78rem;
        height: 0.36rem;
        line-height: 0.36rem;
        text-align: center;
        color: #6e727a;
      }
      .active {
        background: $mainRed;
        color: #fff;
      }
    }
    .double_arrow {
      width: 0.18rem;
      height: 0.14rem;
      margin: 0 auto;
      display: block;
    }
    .tip {
      font-size: 0.12rem;
      line-height: 0.18rem;
      color: #8d8d91;
    }
    .tip:nth-last-child(2) {
      margin-top: 0.16rem;
    }
  }
  .bottomFixed {
    background: #ffffff;
    box-shadow: 0 -2px 4px 0 rgba(242, 244, 246, 0.59);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 0.1rem 0.18rem;
    align-items: flex-end;
    .calculate {
      background: #fdcbcb;
      box-shadow: 0 2px 2px 0 rgba(255, 72, 72, 0.4);
      border-radius: 0.24rem;
      width: 1rem;
      height: 0.48rem;
      font-size: 0.18rem;
      color: #ffffff;
      text-align: center;
      line-height: 0.48rem;
    }
    .active {
      background: $mainRed;
    }
    .clearOut {
      border: 1px solid rgba(141, 142, 147, 0.3);
      border-radius: 0.24rem;
      width: 0.66rem;
      height: 0.32rem;
      color: #090932;
      letter-spacing: -0.15px;
      text-align: center;
      line-height: 0.32rem;
      margin-bottom: 0.02rem;
    }
  }
}
</style>
